<template>
  <div>
    <!-- ... pup視窗-最新消息 ... -->
    <div class="member_pup_news" v-if="topNavId == 1">
      <div class="member_pup_bg" @click="closeNewsDialog"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">最新消息</div>
        <div class="member_pup_main">
          <div class="pup_news_search_container">
            <div class="pup_news_search">
              <el-date-picker
                class="pup_news_search"
                v-model="searchList.logMonth"
                :picker-options="threeMonthLimit"
                @change="getNoticeList(0)"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择月"
              ></el-date-picker>
            </div>
            <div class="pup_news_search">
              <input
                v-model="searchList.query"
                type="text"
                placeholder="搜尋關鍵字"
              />
              <img
                @click="getNoticeList(0)"
                src="../../assets/image/search_icon.png"
                alt="搜尋"
              />
            </div>
          </div>
          <div class="pup_news_head">
            <div class="pup_news_mark_head">置頂</div>
            <div class="pup_news_date_head">發佈日期</div>
            <div class="pup_news_title_head">標題</div>
          </div>
          <div class="pup_news_item_continer">
            <div>
              <div
                v-for="news in latestNewsList"
                :key="news.id"
                class="pup_news_item"
              >
                <div
                  class="pup_news_mark"
                  @click="newsSetTop(news)"
                  :class="{ start_active: news.isLike }"
                ></div>
                <div class="pup_news_date">
                  <span>{{ news.noticeDate }}</span>
                </div>
                <div class="pup_news_title" @click="topLatestnewsDetail(news)">
                  {{ news.newsTitle }}
                </div>
              </div>
            </div>
            <div
              class="pup_news_loading"
              v-if="newHasMore"
              @click="getMoreNotice"
            >
              載入更多消息
            </div>
          </div>
        </div>
        <img
          alt
          class="member_pup_close"
          @click="closeDialog"
          :src="closeIcon"
        />

        <div class="pup_news_detail_container" v-if="latestnewsDetail">
          <div>
            <div class="pup_news_detail_title">
              {{ noticeDetail.newsTitle }}
            </div>
            <div class="pup_news_detail_text">{{ noticeDetail.newsText }}</div>
            <img
              @click="latestnewsDetail = false"
              :src="closeIconGreen"
              class="pup_news_detail_close"
              alt
            />
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-最新消息end ... -->
    <!-- ... pup視窗-上課公約 ... -->
    <div class="member_pup_rule" v-if="topNavId == 2">
      <div class="member_pup_bg" @click="closeDialog"></div>
      <div class="member_pup_container" v-if="modalType == 'student'">
        <div class="member_pup_title">上課公約</div>
        <div class="member_pup_main">
          <div class="pup_rule_main">
            <div class="pup_rule_text_title">
              為提供良好的服務品質，在上課前請務必詳讀以下資訊：
            </div>
            <ol>
              <li>
                為避免因網路、設備等因素影響上課時間，請學生於預定時間前5分鐘上線。
              </li>
              <li>
                如有傳照片或問題給老師的需求，請提早傳給小編轉交。因圖片需要經過處理，最晚至少要在上課前1小時傳問題。
              </li>
              <li>
                上課中請保持該有的上課禮儀，過程中如果需要離席(例如去廁所或是找考卷)，請務必告知老師。
              </li>
              <li>
                若超過預定上課時間15分鐘未上線或通知，該堂課將自動取消，並扣除半堂課點數給老師，請同學務必準時上線。
              </li>
              <li>
                如有調課、請假等需求，務必提前一天告知line小編，小編會協助與老師溝通調整時間。如果連續兩次臨時請假，將影響後續約課權利。
              </li>
              <li>
                每位老師的試教影片都放在Line上，因此不提供試教。但若有換老師的需求可以即時提出，我們會協調老師盡快進行調整。
              </li>
              <li>
                每堂課每位老師都會用心準備課程，安排的進度及作業請同學務必達成。
              </li>
              <li>
                上課過程中如果老師有不合適的言行舉止，下課後可通知小編，我們將視情節更換老師。
              </li>
            </ol>
          </div>
        </div>
        <img
          alt=""
          class="member_pup_close"
          @click="closeDialog"
          :src="closeIcon"
        />
      </div>
      <div class="member_pup_container" v-else>
        <div class="member_pup_title">上課公約</div>
        <div class="member_pup_main">
          <div class="pup_rule_main">
            <div class="pup_rule_text_title">
              為提供良好的服務品質，在上課前請務必詳讀以下資訊：
            </div>
            <ol>
              <li>
                為避免因網路、設備等因素影響上課時間，請老師於預定時間前10分鐘上線。
              </li>
              <li>
                如果學生有傳照片或問題要問老師，請提早傳給小編轉交。因圖片需要經過處理，最晚至少要在上課前1小時傳問題。
              </li>
              <li>
                上課中請保持該有的上課禮儀，過程中如果需要離席(例如去廁所或是找考卷)，請通知學生一聲勿直接行動。
              </li>
              <li>
                若超過預定上課時間2分鐘未上線或通知，將扣半堂課點數給學生。
              </li>
              <li>
                如有調課、請假等需求，務必提前一天告知line小編，小編會協助與學生溝通調整時間。如果連續兩次臨時請假，將影響後續上課權利。
              </li>
              <li>
                上課過程中如果遇到平台的技術性問題，請做初步的故障排除。若判斷會嚴重影響上課，請在第一時間告知客服人員協助處理。
              </li>
              <li>
                為保障老師權益，請老師勿留下個人聯繫資訊給學生，避免被學生或家長騷擾。
              </li>
              <li>如果有出卷或是出作業的需求，請在下課後盡快告知客服人員。</li>
            </ol>
          </div>
        </div>
        <img
          alt
          class="member_pup_close"
          @click="closeDialog"
          :src="closeIcon"
        />
      </div>
    </div>
    <!-- ... pup視窗-上課公約end ... -->
    <!-- ... pup視窗-設備檢測 ... -->
    <div class="member_pup_device" v-if="topNavId == 3">
      <div class="member_pup_bg" @click="closeDialog"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">設備檢測</div>
        <div class="member_pup_main">
          <div class="pup_device_main">
            <div class="pup_device_title">電腦規格</div>
            <div class="pup_device_container">
              <div class="pup_device_row">
                <div class="pup_device_th">CPU</div>
                <div class="pup_device_td">Intel Core i3 3.9 Ghz CPU 以上</div>
              </div>
              <div class="pup_device_row">
                <div class="pup_device_th">作業系統</div>
                <div class="pup_device_td">
                  微軟Windows 7 、Windows8、Windows8.1、Windows10的作業系統
                </div>
              </div>
              <div class="pup_device_row">
                <div class="pup_device_th">硬碟空間</div>
                <div class="pup_device_td">
                  電腦需 4 GB 以上的記憶體 (RAM) / 10 GB以上可用硬碟空間
                </div>
              </div>
              <div class="pup_device_row">
                <div class="pup_device_th">顯示器</div>
                <div class="pup_device_td">
                  顯示至少16bit色和1024x768解析度的顯示器與顯示卡
                </div>
              </div>
              <div class="pup_device_row">
                <div class="pup_device_th">耳機/麥克風</div>
                <div class="pup_device_td">一體成型頭掛式雙接頭耳機/麥克風</div>
              </div>
              <div class="pup_device_row">
                <div
                  class="pup_device_th"
                  style="border-bottom: 1px solid #137a52"
                >
                  網路需求
                </div>
                <div
                  class="pup_device_td"
                  style="border-bottom: 1px solid #757575"
                >
                  建議以有線寬頻連接網際網路－網路頻寬需求為專線光纖16M/8M以上或涵蓋率良好4G手機網路，盡可能避免使用Cable或第四台網路(因非專線連線方式，可能於熱門使用時段有頻寬不穩情況)。
                </div>
              </div>
            </div>
            <div class="pup_device_title">環境測試</div>
            <div class="pup_device_container" style="margin-bottom: 0px">
              <div class="pup_device_test_container">
                <img
                  src="../../assets/image/device_icon_1.png"
                  alt="網路速度測試"
                />
                <div class="pup_device_test_btn">網路速度測試</div>
                <div class="pup_device_test_tip">
                  <span class="test_y">測試結果提示</span>
                </div>
              </div>
              <div class="pup_device_test_container">
                <img
                  src="../../assets/image/device_icon_2.png"
                  alt="視訊鏡頭測試"
                />
                <div class="pup_device_test_btn">視訊鏡頭測試</div>
                <div class="pup_device_test_tip">
                  <span class="test_n" style="display: none">測試結果提示</span>
                </div>
              </div>
              <div class="pup_device_test_container">
                <img
                  src="../../assets/image/device_icon_3.png"
                  alt="耳機麥克風測試"
                />
                <div class="pup_device_test_btn">耳機麥克風測試</div>
                <div class="pup_device_test_tip">
                  <!--<span class="test_y" style="display: none">測試結果提示</span>-->
                </div>
              </div>
              <div class="pup_device_test_container">
                <img
                  src="../../assets/image/device_icon_4.png"
                  alt="電腦裝置測試"
                />
                <div class="pup_device_test_btn">電腦裝置測試</div>
                <div class="pup_device_test_tip">
                <!-- ... 測試結果無法運行 提示字紅色 class="test_n"  ... -->
                  <span class="test_n">裝置無法運行</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          alt
          class="member_pup_close"
          @click="closeDialog"
          :src="closeIcon"
        />
      </div>
    </div>
    <!-- ... pup視窗-設備檢測end ... -->
    <!-- ... pup視窗-常見問題 ... -->
    <div class="member_pup_qa" v-if="topNavId == 4">
      <div class="member_pup_bg" @click="closeDialog"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">常見問題</div>
        <div class="member_pup_main">
          <div class="pup_qa_main">
            <table cellpadding="0" cellspacing="0" style="width: 100%">
              <tbody v-for="(item, i) in questionList" :key="i">
                <tr>
                  <td class="qa_number">Q{{ i + 1 }}</td>
                  <td class="qa_question">{{ item.question }}</td>
                  <td class="qa_answer_bu" @click="showResult(i)">回答</td>
                </tr>
                <tr v-if="item.show">
                  <td class="qa_answer" colspan="4" v-html="item.result"></td>
                </tr>
                <tr>
                  <td colspan="4" style="height: 30px"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <img
          alt
          class="member_pup_close"
          @click="closeDialog"
          :src="closeIcon"
        />
      </div>
    </div>
    <!-- ... pup視窗-常見問題end ... -->
  </div>
</template>
<script>
import closeIcon from "@/assets/image/close_icon.png";
import closeIconGreen from "@/assets/image/close_icon_green.png";
import { getHsdLikeNotice, getHsdNotices } from "../../api/api";
export default {
  props: {
    modalType: "",
    topNavId: 0,
    userInfo: {},
  },
  data() {
    return {
      closeIcon,
      closeIconGreen,
      searchList: {
        pageNum: 1,
        pageSize: 10,
        query: "",
        logMonth: "",
      },
      latestNewsList: [],
      latestnewsDetail: false,
      newHasMore: false,
      noticeDetail: {},
      studentQA: [
        {
          show: false,
          question: "點數有期限嗎？",
          result:
            "訂購後3個月內須啟用服務，啟用後一年內須使用完畢。若有其他因素無法如期啟用，請提早告知顧問填寫申請單延時，但僅限一次。",
        },
        {
          show: false,
          question: "上課所需的電腦規格和網路需求為何？",
          result: "請參考設備檢測頁面。",
        },
        {
          show: false,
          question: "網路斷線或不穩怎麼辦？",
          result:
            "如果還沒上課，請先使用speedtest網頁(https://www.speedtest.net/zh-Hans)進行測速，如果網路環境不好或不穩定，可以換一個網路再試試看，建議使用有線寬頻網路或涵蓋率良好的4G手機網路。",
        },
        {
          show: false,
          question: "沒畫面、麥克風、耳機沒聲音怎麼辦？",
          result:
            "課前請先檢查網路攝影機、麥克風、耳機功能是否正常，如果有異常可拔掉網路攝影機、耳機或麥克風，用筆記型電腦內建的設備即可。",
        },
        {
          show: false,
          question: "老師教得太難或太簡單，怎麼辦？",
          result:
            "上課過程中可不斷跟老師進行互動並勇敢發問，老師皆是真人且絕對可以配合學生學習進度進行調整。",
        },
        {
          show: false,
          question: "忘記下次上課時間怎麼辦？",
          result:
            "會員中心頁面可直接查詢「我的課表」，就可以知道下次上課資訊。",
        },
        {
          show: false,
          question: "學生有學校作業或考卷問題想問怎麼辦？",
          result:
            "可在課前使用手機拍照後傳給line官方帳號由小編傳給老師。因照片處理需要時間，請至少在上課前1小時傳送問題給小編。拍照時記得將題目盡量清晰放大喔！",
        },
        // {
        //   show: false,
        //   question: "學生有學校作業或考卷問題想問怎麼辦？",
        //   result:
        //     "可在課前使用手機拍照後傳給line官方帳號由小編傳給老師。因照片處理需要時間，請至少在上課前1小時傳送問題給小編。拍照時記得將題目盡量清晰放大喔！",
        // },
        {
          show: false,
          question: "zoom的下載位置或操作問題？",
          result:
            "zoom的下載網址為https://zoomnow.net/zntw_zoom_download.php?showType=ALL 。<br />如有操作問題可透過Line詢問小編或致電(02)2311-3260。",
        },
      ],
      questionList: [
        {
          show: false,
          question: "時間到學生沒上線怎麼辦？",
          result:
            "請立即通知客服人員協助聯繫學生，若超過10分鐘未上線且未通知，則扣半堂課點數給老師。",
        },
        {
          show: false,
          question: "上課所需的電腦規格和網路需求為何？",
          result: "請參考設備檢測頁面。",
        },
        {
          show: false,
          question: "網路斷線或不穩怎麼辦？",
          result:
            "如果還沒上課，請先使用speedtest網頁(https://www.speedtest.net/zh-Hans)進行測速，如果網路環境不好或不穩定，可以換一個網路再試試看，建議使用有線寬頻網路或涵蓋率良好的4G手機網路。",
        },
        {
          show: false,
          question: "沒畫面、麥克風、耳機沒聲音怎麼辦？",
          result:
            "課前請先檢查網路攝影機、麥克風、耳機功能是否正常，如果有異常可拔掉網路攝影機、耳機或麥克風，用筆記型電腦內建的設備即可。",
        },
        {
          show: false,
          question: "學生反應冷淡，不願意互動怎麼辦？",
          result:
            "上課過程中記得不斷丟問題詢問同學，保持學生的專注力，並可以適時停下來讓學生自行練習作答，切勿自己悶著頭一直上；也可以在上課過程中尋找學生有興趣的內容，適時加入一些生活或有趣味性的話題進去。",
        },
        {
          show: false,
          question: "時間到學生沒上線怎麼辦？",
          result:
            "上課過程中記得不斷丟問題詢問同學，保持學生的專注力，並可以適時停下來讓學生自行練習作答，切勿自己悶著頭一直上；也可以在上課過程中尋找學生有興趣的內容，適時加入一些生活或有趣味性的話題進去。",
        },
        {
          show: false,
          question: "如果我的帳號或密碼忘記了怎麼辦？",
          result:
            "請至會員登入區點選「忘記帳號」或「忘記密碼」，在出現視窗中之欄位填寫資料...",
        },
        {
          show: false,
          question: "學生有學校作業或考卷問題想問怎麼辦？",
          result:
            "可在課前使用手機拍照後傳給line官方帳號由小編傳給老師。因照片處理需要時間，請至少在上課前1小時傳送問題給小編。拍照時記得將題目盡量清晰放大喔！",
        },
        {
          show: false,
          question: "zoom的下載位置或操作問題？",
          result:
            "中華電信zoom的下載網址為 https://zoom.emome.net/zoom_download.php 。如有操作問題可透過Line詢問小編或致電(02)2311-3260。",
        },
      ],
      threeMonthLimit: {
        disabledDate: (month) => {
          return false;
        },
      },
    };
  },
  created() {
    let date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    this.searchList.logMonth = `${year}-${month}`;
    if (this.modalType && this.modalType == "student") {
      this.questionList = this.studentQA;
    }
  },
  methods: {
    //获取最新消息
    async getNoticeList(id) {
      let messageList = Object.assign({}, this.searchList, this.userInfo);
      // console.log(this.latestNewsList, id);
      const res = await getHsdNotices(messageList);
      if (res && res.success == 1) {
        let newsList = res.data.data ? res.data.data : [];
        if (id) {
          this.latestNewsList = this.latestNewsList.cancat(newsList);
        } else {
          this.latestNewsList = newsList;
        }
        this.newHasMore = res.data.total_page > this.searchList.pageNum;
      }
    },
    //更多消息加载
    getMoreNotice() {
      this.searchList.pageNum++;
      this.getNoticeList(1);
    },
    //最新消息置顶
    newsSetTop(news) {
      this.getNoticeLikes(news);
    },
    async getNoticeLikes(news) {
      const res = await getHsdLikeNotice({
        id: news.id,
        userId: this.userInfo.userId,
      });
      if (res && res.success == 1) {
        news.isLike = !news.isLike;
      }
    },
    //最新消息详情
    topLatestnewsDetail(news) {
      this.noticeDetail = news;
      this.latestnewsDetail = true;
    },
    //关闭最新消息弹窗
    closeNewsDialog() {
      this.$emit("update:topNavId", 0);
      this.latestnewsDetail = false;
    },
    closeDialog() {
      this.$emit("update:topNavId", 0);
    },
    //常见问题
    showResult(i) {
      let show = this.questionList[i].show;
      this.questionList[i].show = !show;
    },
  },
  mounted() {},
};
</script>
<style >
@import "../../assets/css/member_center.css";
@import "../../assets/css/clndr.css";
.pup_news_search.el-input__inner {
  border: 1px solid #9e9e9e !important;
  color: #757575;
}
.el-input__prefix {
  color: #757575;
}
</style>